import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { NavBarStyles } from "./NavBarStyles"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"

import { Link } from "react-router-dom";
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import FlagIcon from '@mui/icons-material/Flag';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import WarningIcon from '@mui/icons-material/Warning';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { GlobalStyles } from "../../GlobalStyles"
import { HoverMenu } from "../../helpers"

type NavBarMenuWebProps = {
    navigateToPage: (page: string) => void
}

export default function NavBarMenuWeb({navigateToPage}: NavBarMenuWebProps) {
    return(
        <>
            <Grid item 
                    xs={10} sm={10} md={10} lg = {10}
                    sx={{
                        display: { xs: "none", sm: "none", md: "block", lg: "block" }
                      }}
                    >

                    <div style = {NavBarStyles.navbarLayout}>

                      <div style = {{position: 'absolute', marginRight: '212px'}}>
                        <Link to="/">
                            <Button aria-label = "homeButton" style = {GlobalStyles.navbarButton}>
                                <Typography style = {GlobalStyles.navbarText}>
                                    Home
                                </Typography>
                            </Button>
                        </Link>
                        </div>

                        {/* <Link to = "">
                            <Button aria-label = "whyUsButton" style = {GlobalStyles.navbarButton}>
                                <Typography style = {GlobalStyles.navbarText}>
                                    Why us?
                                </Typography>
                            </Button>
                        </Link>
                        
                        <div>
                        <HoverMenu title=
                            {
                            <div>
                                <Typography style = {NavBarStyles.hoverMenuBoldText}>
                                    What We Do
                                </Typography>
                                <Divider />
                                <Typography style = {NavBarStyles.hoverMenuRegularText}>
                                    Get your vehicle or motorcycle shipped
                                </Typography>
                                
                                <div style = {{height: '225px', display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                                    <div style = {NavBarStyles.menuItem}>
                                        
                                        <MenuItem onClick = {() => navigateToPage("cross-country-auto-transport")}>
                                            <MapIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Cross Country
                                            </Typography>
                                        </MenuItem>

                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("open-auto-transport")}>
                                            <DriveEtaIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Open Transport
                                            </Typography>
                                        </MenuItem>
                                    </div> 
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("enclosed-auto-transport")}>
                                            <LocalShippingIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Enclosed Transport
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("auto-transport-rail")}>
                                            <DirectionsTransitIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;By Rail
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("military-auto-shipping")}>
                                            <FlagIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Military Car Shipping
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("auction-car-transport-services")}>
                                            <GavelIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Auto Auction Shipping
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("door-to-door-auto-transport")}>
                                            <HomeIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Door To Door
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("ship-car-from-hawaii-to-mainland")}>
                                            <PublicIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;From Hawaii To Mainland
                                            </Typography>
                                        </MenuItem>
                                    </div>     
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("ship-car-from-california-to-hawaii")}>
                                            <BeachAccessIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;From California to Hawaii
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("heavy-equipment-transportation")}>
                                            <WarningIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Heavy Equipment Transport
                                            </Typography>
                                        </MenuItem>
                                    </div>
                                    <div style = {NavBarStyles.menuItem}>
                                        <MenuItem onClick = {() => navigateToPage("motorcycle-transport-services")}>
                                            <TwoWheelerIcon fontSize = "small" />
                                            <Typography style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                &nbsp;Motorcycle Shipping
                                            </Typography>
                                        </MenuItem>
                                    </div>                       
                                
                                </div>
                            </div>
                            
                            }>
                            <Button aria-label = "servicesButton" style = {GlobalStyles.navbarButton}>
                                <Typography style = {GlobalStyles.navbarText}>
                                    Auto Shipping Services
                                </Typography>
                                <KeyboardArrowDownIcon style = {{color: 'black'}} />
                            </Button>
                        </HoverMenu>
                        </div>
                        
                        <div>
                        <HoverMenu title=
                            {
                                <div>                               
                                    <div style = {{height: '225px', display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("cheapest-way-to-ship-a-car")}>
                                                <MapIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Cheapest Way To Ship A Car
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("auto-shipping-faq")}>
                                                <DriveEtaIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;FAQS
                                                </Typography>
                                            </MenuItem>
                                        </div> 
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("how-much-does-it-cost-to-ship-a-car")}>
                                                <LocalShippingIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;How Much Does Shipping A Car Cost
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("auto-transport-process-start-finish")}>
                                                <DirectionsTransitIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Auto Transport: Start To Finish
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("auto-shipping-rates-calculator")}>
                                                <FlagIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Auto Shipping Rates Calculator
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("auto-shipping-broker-vs-car-carrier")}>
                                                <GavelIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Brokers Vs. Carriers
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("differences-open-enclosed-auto-transportation-services")}>
                                                <HomeIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Open Vs. Enclosed
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("driving-vs-auto-transport-analysis")}>
                                                <PublicIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Driving Vs. Auto Transport
                                                </Typography>
                                            </MenuItem>
                                        </div>     
                                        <div style = {NavBarStyles.menuItem}>
                                            <MenuItem onClick = {() => navigateToPage("shipping-a-car-vs-towing-a-car-what-to-know")}>
                                                <BeachAccessIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Shipping A Car Vs. Towing A Car
                                                </Typography>
                                            </MenuItem>
                                        </div>                                                         
                                    </div>
                                </div>
                                }>
                        <Button aria-label = "informationButton" style = {GlobalStyles.navbarButton}>
                            <Typography style = {GlobalStyles.navbarText}>
                                Useful Information
                            </Typography>
                            <KeyboardArrowDownIcon style = {{color: 'black'}} />
                        </Button>
                        </HoverMenu>
                        
                        </div>

                        <Button aria-label = "aboutUsButton" style = {GlobalStyles.navbarButton}>
                            <Typography style = {GlobalStyles.navbarText}>
                                About us
                            </Typography>
                        </Button>

                        <Link to= "">
                        <Button aria-label = "blogButton" style = {GlobalStyles.navbarButton}>
                            <Typography style = {GlobalStyles.navbarText}>
                                Blog
                            </Typography>
                        </Button>
                        </Link> */}

                    </div>

                </Grid>
            </>
    )
}