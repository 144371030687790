/* eslint-disable jsx-a11y/no-distracting-elements */
import { useContext, useState } from 'react';

//MUI IMPORTS
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid'


import aaslogo from '../../assets/aaslogo.png';
import { GlobalStyles } from '../../GlobalStyles';
import { Link } from "react-router-dom";
import { OMIT_STYLING_PAGES_SLUG_NAMES } from '../../helpers.js';
import { useNavigate } from "react-router-dom";
import { NavBarStyles } from './NavBarStyles';

import NewsBar from './NewsBar';
import NavBarMenuMobile from './NavBarMenuMobile';
import NavBarMenuWeb from './NavBarMenuWeb';
import { PageContext } from '../../App';

export default function NavBar() {

    const {isMobile} = useContext(PageContext)
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigateToPage = async(slug: string) => {
        let omitPageFormatting = OMIT_STYLING_PAGES_SLUG_NAMES.includes(slug) ? true : false;
        navigate(`/${slug}/`, { replace: true, state: {slug: slug, omitPageFormatting: omitPageFormatting} })
        handleClose();
        window.scrollTo(0,0);
    }

    console.log("IS MOBILE")
    console.log(isMobile)


  return (
      <div style = {NavBarStyles.appBar}>

      <NewsBar />

        <div>
            <Grid container>
                <Grid item xs={2} sm={2} md={2}>
                    <Link to = "/">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        style = {GlobalStyles.navbarButton}
                        aria-label = "AASLogoButton"
                    >
                        <img alt = "" src = {aaslogo} style = {{height: '60px', aspectRatio: 'auto'}}/>

                    </IconButton>
                    </Link>
                </Grid>

                {isMobile &&
                    <NavBarMenuMobile 
                        handleClick={handleClick}
                        handleClose={handleClose}
                        navigateToPage={navigateToPage}
                        open={open}
                        anchorEl={anchorEl}
                    />
                }

                {!isMobile &&
                    <NavBarMenuWeb
                        navigateToPage={navigateToPage}
                    />
                }

            </Grid>

        </div>
      </div>
  );
}