import { colors } from "../../theme";

export const NavBarStyles = {
    navbarLayout: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-evenly'
    },
    navbarLayoutAlt: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        height: '100%',
        justifyContent: 'flex-end'
    },
    callButton: {
        backgroundColor: 'black',
        borderRadius: 32,
        minWidth: '330px',
        marginLeft: '8px'
    },
    callButtonMobile: {
        backgroundColor: 'black',
        minWidth: '250px',
        borderRadius: 32
    },
    callText: {
        fontSize: 16,
        fontWeight: 900,
        fontFamily: 'Montserrat-ExtraBold',
        color:'#FFF200'
    },
    hoverMenuBoldText: {
        fontSize: 16,
        fontWeight: 900,
        fontFamily: 'Montserrat-ExtraBold',
        color:'black'
    },
    hoverMenuRegularText: {
        fontSize: 12,
        fontFamily: 'Montserrat',
        color:'black'
    },
    hoverMenuRegularTextBold: {
        fontSize: 12,
        fontFamily: 'Montserrat-Bold',
        fontWeight: 700,
        color:'black'
    },
    scrollText: {
        fontSize: 16,
        fontFamily: 'Montserrat',
        color:'black'
    },
    scrollTextMobile: {
        fontSize: 12,
        fontFamily: 'Montserrat',
        color:'black'
    },
    scrollTextEmphasis: {
        fontSize: 16,
        fontFamily: 'Montserrat-Bold',
        fontWeight: 700,
        color: colors.primary.alternate
    },
    menuItem: {
        width: '250px'
    },
    menuItemMobile: {
        width: '50%'
    },
    bannerToolbar: {
        minHeight: '35px',
        height: '35px',
        display: 'flex', 
        alignItems: 'center', 
        backgroundColor: 'rgba(0, 0, 0, 0.05',
        width: '100%',
        zIndex: 100,
    } ,
    appBar: {
        position: "fixed", 
        backgroundColor: 'white', 
        width: '100%', 
        zIndex: 100, 
        boxShadow: "2px 1px 3px #9E9E9E"

    } as React.CSSProperties
}