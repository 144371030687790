import TextField from "@mui/material/TextField";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip"
import { styled } from '@mui/system';
import { API, graphqlOperation } from 'aws-amplify';
import {  getPosts, getPages, postsByDate } from './graphql/queries';

const EnvVariables = require("./EnvVariables").EnvVariables

export const OMIT_STYLING_PAGES_SLUG_NAMES = [
   "auto-transport-states"
]

export const STATE_CODES = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'];
export const CARS = [
   {
      make: "Ford",
      vehicles: [
         {
            name: "Fiesta",
            slug: "ford-fiesta-auto-shipping"
         },
         {
            name: "Super Duty",
            slug: "ford-super-duty-auto-transportatio"
         },
         {
            name: "Focus",
            slug: "ford-focus-auto-transport"
         },
         {
            name: "Fusion",
            slug: "ford-fusion-car-shipping"
         },
         {
            name: "Mustang",
            slug: "ford-mustang-auto-shipping"
         },
         {
            name: "Fusion",
            slug: "ford-fusion-car-shipping"
         },
         {
            name: "Taurus",
            slug: "ford-taurus-auto-transportation"
         },
         {
            name: "Escape",
            slug: "ford-escape-auto-transportation"
         },
         {
            name: "Edge",
            slug: "ford-edge-auto-transportation"
         },
         {
            name: "Explorer",
            slug: "ford-explorer-auto-transportation"
         },
         {
            name: "Flex",
            slug: "ford-flex-auto-transportation"
         },
         {
            name: "Expedition",
            slug: "ford-expedition-auto-transportation"
         },
         {
            name: "Transit Connect",
            slug: "ford-transit-connect-auto-transportation"
         },
         {
            name: "Ranger",
            slug: "ford-ranger-auto-transportation"
         },
         {
            name: "Explorer",
            slug: "ford-explorer-auto-transportation"
         },
         {
            name: "F-150 Series",
            slug: "ford-f-150-auto-transporters"
         },
         {
            name: "E-Series Wagon",
            slug: "e-series-wagon-auto-shipping"
         },
      ]
   },
   {
      make: "Chevrolet",
      vehicles: [
         {
            name: "Sonic",
            slug: "chevy-sonic-auto-shipping"
         },
         {
            name: "Cruze",
            slug: "chevrolet-cruze-auto-transportation"
         },
         {
            name: "Volt",
            slug: "chevrolet-volt-auto-transport"
         },
         {
            name: "Malibu",
            slug: "chevy-malibu-car-transport"
         },
         {
            name: "Cruze",
            slug: "chevy-cruze-auto-transportation"
         },
         {
            name: "Corvette",
            slug: "chevrolet-corvette-vehicle-transportation"
         },
         {
            name: "Camaro",
            slug: "chevrolet-camaro-vehicle-transportation"
         },
         {
            name: "Traverse",
            slug: "chevrolet-traverse-auto-transport"
         },
         {
            name: "Equinox",
            slug: "chevrolet-equinox-auto-transport"
         },
         {
            name: "Impala",
            slug: "chevrolet-impala-car-transport"
         },
         {
            name: "Tahoe",
            slug: "chevrolet-tahoe-auto-shipping"
         },
         {
            name: "Tahoe Hybrid",
            slug: "chevrolet-tahoe-hybrid-auto-transport"
         },
         {
            name: "Avalanche",
            slug: "chevrolet-avalanche-2wd-auto-transport"
         },
         {
            name: "1/2 Ton Suburban",
            slug: "chevrolet-suburban-12-ton-auto-transportion"
         },
         {
            name: "3/4 Ton Suburban",
            slug: "chevrolet-suburban-34ton-vehicle-transport"
         },
         {
            name: "Colorado Regular Cab",
            slug: "chevrolet-colorado-regular-cab-vehicle-shipping"
         },
         {
            name: "Colorado Extended Cab 2WD",
            slug: "chevrolet-colorado-extended-cab-2wd-auto-transport"
         },
         {
            name: "Colorado Extended Cab AWD",
            slug: "chevrolet-colorado-extended-cab-awd-auto-shipping"
         },
         {
            name: "Colorado Crew Cab 2WD",
            slug: "chevrolet-colorado-crew-cab-2wd-auto-shipping"
         },
         {
            name: "Silverado 1500HD Regular Cab Standard Bed",
            slug: "chevrolet-silverado-1500-regular-cab-standard-bed-auto-shipping"
         },
         {
            name: "Silverado 1500HD Regular Cab Long Bed",
            slug: "chevrolet-silverado-1500-regular-cab-long-bed-auto-transport"
         },
         {
            name: "Silverado 1500HD Extended Cab Long Bed",
            slug: "chevrolet-silverado-1500-extended-cab-long-bed-vehicle-transport"
         },
         {
            name: "Silverado 1500HD Extended Cab Standard Bed",
            slug: "chevrolet-silverado-1500-extended-cab-standard-bed-auto-shipping"
         },
         {
            name: "Silverado 2500HD Regular Cab",
            slug: "auto-transport-chevrolet-silverado-2500-hd-regular-cab"
         },
         {
            name: "Silverado 2500HD Extended Cab",
            slug: "auto-transportion-chevrolet-silverado-2500hd-extended-cab"
         },
         {
            name: "Silverado 2500HD Crew Cab",
            slug: "auto-transportion-chevrolet-silverado-2500hd-crew-cab"
         },
         {
            name: "Silverado 3500HD Regular Cab",
            slug: "auto-shipping-chevrolet-silverado-3500hd-regular-cab"
         },
         {
            name: "Silverado 3500HD Extended Cab",
            slug: "auto-transportation-chevy-silverado-3500hd-extended-cab"
         },
         {
            name: "Silverado 3500HD Crew Cab Standard Bed",
            slug: "auto-transport-for-a-chevy-silverado-3500hd-crew-cab-standard-bed"
         },
         {
            name: "Silverado 3500HD Crew Cab Long Bed",
            slug: "auto-transportion-chevy-silverado-3500hd-crew-cab-long-bed"
         },
         {
            name: "Silverado Hybrid",
            slug: "vehicle-transport-chevrolet-silverado-hybrid"
         },
         {
            name: "Express 1500 Passenger Van",
            slug: "vehicle-shipping-chevrolet-express-1500-passenger-van"
         },
         {
            name: "Express 1500 Cargo Van",
            slug: "auto-transport-chevrolet-express-1500-cargo-van"
         },
         {
            name: "Express 2500 Passenger Van",
            slug: "vehicle-transport-chevrolet-express-2500-passenger-van"
         },
         {
            name: "Express 2500 Cargo Van",
            slug: "car-shipping-chevrolet-express-2500-cargo-van"
         },
         {
            name: "Express 3500 Passenger Van",
            slug: "vehicle-transportation-chevy-express-3500-passenger-van"
         },
         {
            name: "Express 3500 Diesel Passenger Van",
            slug: "car-transport-chevrolet-express-3500-diesel-passenger-van"
         },
         {
            name: "Express 3500 Cargo Van",
            slug: "auto-transport-chevrolet-express-3500-cargo-van"
         },
         {
            name: "Express 3500 Diesel Cargo Van",
            slug: "auto-shipping-chevrolet-express-3500-diesel-cargo-van"
         },
      ]
   },
   {
      make: "Dodge",
      vehicles: [
         {
            name: "Avenger",
            slug: "auto-transport-dodge-avenger"
         },
         {
            name: "Caliber",
            slug: "auto-shipping-dodge-caliber"
         },
         {
            name: "Challenger",
            slug: "auto-transportion-dodge-challenger"
         },
         {
            name: "Charger Part I",
            slug: "car-transport-dodge-charger-part-1"
         },
         {
            name: "Charger Part II",
            slug: "car-transport-dodge-charger-part-2"
         },
         {
            name: "Dart",
            slug: "car-shipping-dodge-dart"
         },
         {
            name: "Durango",
            slug: "car-transportion-dodge-durango"
         },
         {
            name: "Journey",
            slug: "vehicle-transport-dodge-journey"
         },
         {
            name: "Grand Caravan",
            slug: "vehicle-shipping-dodge-grand-caravan"
         }
      ]
   },
   {
      make: "Toyota",
      vehicles: [
         {
            name: "Yaris",
            slug: "vehicle-transportation-toyota-yaris"
         },
         {
            name: "Corolla",
            slug: "auto-transport-toyota-corolla"
         },
         {
            name: "Matrix",
            slug: "auto-shipping-toyota-matrix"
         },
         {
            name: "Camry",
            slug: "auto-transportation-toyota-camry"
         },
         {
            name: "Sienna",
            slug: "vehicle-transport-toyota-sienna"
         },
         {
            name: "Avalon",
            slug: "vehicle-shipping-toyota-avalon"
         },
         {
            name: "Prius",
            slug: "vehicle-transportation-toyota-prius"
         },
         {
            name: "Prius C",
            slug: "car-transport-toyota-prius-ici"
         },
         {
            name: "Prius V",
            slug: "car-shipping-toyota-prius-v"
         },
         {
            name: "Prius Plug-In Hybrid",
            slug: "car-transportation-toyota-prius-plugin-hybdrid"
         },
         {
            name: "RAV4",
            slug: "auto-transport-toyota-rav4"
         },
         {
            name: "FJ Cruiser",
            slug: "auto-shipping-toyota-fj-cruiser"
         },
         {
            name: "Venza",
            slug: "auto-transportation-toyota-venza"
         },
         {
            name: "Highlander",
            slug: "vehicle-transport-toyota-highlander"
         },
         {
            name: "4Runner",
            slug: "vehicle-shipping-toyota-4runner"
         },
      ]
   },
   {
      make: "Mazda",
      vehicles: [
         {
            name: "Mazda 2",
            slug: "auto-transport-mazda-2"
         },
         {
            name: "Mazda 3",
            slug: "auto-transport-mazda-3"
         },
         {
            name: "Mazda 5",
            slug: "auto-shipping-mazda-5"
         },
         {
            name: "Mx-5 Miata",
            slug: "car-shipping-mazda-mx5"
         },
         {
            name: "CX-5",
            slug: "auto-transport-mazda-cx-5"
         },
         {
            name: "CX-9",
            slug: "auto-shipping-mazda-cx-9"
         },
      ]
   },
   {
      make: "Chrysler",
      vehicles: [
         {
            name: "200",
            slug: "car-transport-chrysler-200"
         },
         {
            name: "200 Convertible",
            slug: "car-shipping-chrysler-200-convertible"
         },
         {
            name: "300",
            slug: "vehicle-transport-chrysler-300"
         },
         {
            name: "300-Convertible",
            slug: "vehicle-transport-chrysler-300-convertible"
         },
         {
            name: "Town & Country",
            slug: "auto-transport-chrysler-town-country"
         },
      ]
   },
   {
      make: "Nissan",
      vehicles: [
         {
            name: "Versa Sedan",
            slug: "auto-transport-nissan-versa-sedan"
         },
         {
            name: "Versa Note",
            slug: "auto-shipping-nissan-versa-note"
         },
         {
            name: "Sentra",
            slug: "car-transport-nissan-sentra"
         },
         {
            name: "Cube",
            slug: "car-shipping-nissan-cube"
         },
         {
            name: "Altima Sedan",
            slug: "auto-transport-nissan-altima-sedan"
         },
         {
            name: "Altima Coupe",
            slug: "auto-shipping-nissan-altima-coupe"
         },
         {
            name: "Maxima",
            slug: "car-transport-nissan-maxima"
         },
         {
            name: "Leaf",
            slug: "car-shipping-nissan-leaf"
         },
         {
            name: "Pathfinder",
            slug: "vehicle-transport-nissan-pathfinder"
         },
         {
            name: "Pathfinder Hybrid",
            slug: "vehicle-shipping-nissan-pathfinder-hybrid"
         },
         {
            name: "370z",
            slug: "auto-transport-nissan-370z"
         },
         {
            name: "GT-R",
            slug: "auto-shipping-nissan-gtr"
         },
         {
            name: "Juke",
            slug: "car-transport-nissan-juke"
         },
         {
            name: "Rogue",
            slug: "car-shipping-nissan-rogue"
         },
         {
            name: "Xterra",
            slug: "vehicle-transport-nissan-xterra"
         },
         {
            name: "Murano",
            slug: "vehicle-transport-nissan-murano"
         },
         {
            name: "Armada",
            slug: "vehicle-shipping-nissan-armada"
         },
         {
            name: "Quest",
            slug: "auto-transport-nissan-quest"
         },
         {
            name: "Frontier",
            slug: "auto-shipping-nissan-frontier"
         },
         {
            name: "Titan",
            slug: "car-transport-nissan-titan"
         },
         {
            name: "NV Lineup",
            slug: "car-shipping-nissan-nv-lineup"
         },
      ]
   },
   {
      make: "Hyundai",
      vehicles: [
         {
            name: "Accent",
            slug: "auto-transport-hyundai-accent"
         },
         {
            name: "Elantra",
            slug: "auto-shipping-hyundai-elantra"
         },
         {
            name: "Veloster",
            slug: "car-transport-hyundai-veloster"
         },
         {
            name: "Sonata",
            slug: "car-shipping-hyundai-sonata"
         },
         {
            name: "Azera",
            slug: "vehicle-transport-hyundai-azera"
         },
         {
            name: "Sonata Hybrid",
            slug: "vehicle-shipping-hyundai-sonata-hybrid"
         },
         {
            name: "Tucson",
            slug: "auto-transport-hyundai-tucson"
         },
         {
            name: "Santa Fe",
            slug: "auto-shipping-hyundai-santa-fe"
         },
         {
            name: "Santa Fe Sport",
            slug: "car-transport-hyundai-santa-fe-sport"
         },
         {
            name: "Genesis",
            slug: "car-shipping-hyundai-genesis"
         },
         {
            name: "Genesis Coupe",
            slug: "vehicle-transport-hyundai-genesis-coupe"
         },
         {
            name: "Equus",
            slug: "vehicle-shipping-hyundai-equus"
         }
      ]
   }
]

export const STATES = [
   {
       text: "Alabama (AL)",
       slug: "AL-Alabama",
       cities: [
         {
            name: "Birmingham",
            slug: "birmingham-al"
         },
         {
            name: "Huntsville",
            slug: "huntsville-al"
         },
         {
            name: "Mobile",
            slug: "mobile-al"
         },
         {
            name: "Montgomery",
            slug: "montgomery-al"
         }
       ]
   },
   {
       text: "Alaska (AK)",
       slug: "AK-Alaska",
       cities: [
         {
            name: "Anchorage",
            slug: "anchorage-ak"
         }
       ]
   },
   {
       text: "Arizona (AZ)",
       slug: "AZ-Arizona",
       cities: [
         {
            name: "Phoenix",
            slug: "phoenix-az"
         },
         {
            name: "Prescott",
            slug: "prescott-az"
         },
         {
            name: "Glendale",
            slug: "glendale-az"
         }
       ]
   },
   {
       text: "Arkansas (AR)",
       slug: "AR-Arkansas"
   },
   {
       text: "California (CA)",
       slug: "CA-California",
       cities: [
         {
            name: "Sacramento",
            slug: "sacramento-ca"
         },
         {
            name: "San Diego",
            slug: "san-diego-ca"
         },
         {
            name: "San Francisco",
            slug: "san-francisco-ca"
         },
         {
            name: "Los Angeles",
            slug: "la-los-angeles-ca"
         },
         {
            name: "San Jose",
            slug: "sanjose-ca"
         },
         {
            name: "Riverside",
            slug: "riverside-ca"
         },
         {
            name: "Long Beach",
            slug: "longbeach-ca"
         },
         {
            name: "Oakland",
            slug: "oakland-ca"
         },
         {
            name: "Yuba City",
            slug: "yuba-city-ca"
         },
         {
            name: "Fresno",
            slug: "fresno-ca"
         },
         {
            name: "Bakersfield",
            slug: "bakersfield-ca"
         },
         {
            name: "Carlsbad",
            slug: "carlsbad-ca"
         }
       ]
   },
   {
       text: "Colorado (CO)",
       slug: "CO-Colorado",
       cities: [
         {
            name: "Boulder",
            slug: "boulder-co"
         }
       ]
   },
   {
       text: "Connecticut (CT)",
       slug: "CT-Connecticut",
       cities: [
         {
            name: "Hartford",
            slug: "hartford-ct"
         }
       ]
   },
   {
       text: "Delaware (DE)",
       slug: "DE-Delaware"
   },
   {
       text: "District if Columbia (DC)",
       slug: "DC-District-of-Columbia"
   },
   {
       text: "Florida (FL)",
       slug: "FL-Florida",
       cities: [
         {
            name: "Miami",
            slug: "miami-fl"
         },
         {
            name: "Jacksonville",
            slug: "jacksonville-fl"
         },
         {
            name: "Orlando",
            slug: "orlando-fl"
         },
         {
            name: "Daytona Beach",
            slug: "daytona-beach-fl"
         },
         {
            name: "Sarasota",
            slug: "sarasota-fl"
         },
         {
            name: "Punta Gorda",
            slug: "punta-gorda-fl"
         },
         {
            name: "Bradenton",
            slug: "bradenton-fl"
         },
         {
            name: "Port St. Lucie",
            slug: "portstlucie-fl"
         },
         {
            name: "Vero Beach",
            slug: "verobeach-fl"
         },
         {
            name: "Kissimmee",
            slug: "kissimmee-fl"
         },
         {
            name: "Winter Haven",
            slug: "winterhaven-fl"
         },
         {
            name: "Venice",
            slug: "venice-fl"
         },
         {
            name: "Tampa",
            slug: "auto-transport-tampa"
         },
         {
            name: "St Petersburg",
            slug: "auto-transport-st-petersburg"
         }
       ]
   },
   {
       text: "Georgia (GA)",
       slug: "GA-Georgia",
       cities: [
         {
            name: "Atlanta",
            slug: "atlanta-ga"
         },
         {
            name: "Columbus",
            slug: "columbus-ga"
         },
         {
            name: "Macon",
            slug: "macon-ga"
         },
         {
            name: "Savannah",
            slug: "savannah-ga"
         },
         {
            name: "Fort Stewart",
            slug: "fortstewart-ga"
         },
         {
            name: "Warner Robins",
            slug: "warnerrobins-ga"
         }
       ]
   },
   {
       text: "Hawaii (HI)",
       slug: "HI-Hawaii",
       cities: [
         {
            name: "Honolulu",
            slug: "auto-transport-honolulu"
         }
       ]
   },
   {
       text: "Idaho (ID)",
       slug: "ID-Idaho",
       cities: [
         {
            name: "Boise",
            slug: "boise-id"
         },
         {
            name: "Idaho Falls",
            slug: "idahofalls-id"
         },
         {
            name: "Lewiston",
            slug: "lewiston-id"
         },
         {
            name: "Meridian",
            slug: "auto-transport-meridian"
         }
       ]
   },
   {
       text: "Illinois (IL)",
       slug: "IL-Illinois",
       cities: [
         {
            name: "Champaign",
            slug: "champaign-il"
         },
         {
            name: "Chicago",
            slug: "chicago-il"
         },
         {
            name: "Peoria",
            slug: "peoria-il"
         },
         {
            name: "Springfield",
            slug: "springfield-il"
         },
         {
            name: "Farmington",
            slug: "farmington-il"
         },
         {
            name: "Rochelle",
            slug: "rochelle-il"
         }
       ]
   },
   {
       text: "Indiana (IN)",
       slug: "IN-Indiana",
       cities: [
         {
            name: "Fort Wayne",
            slug: "fortwayne-in"
         },
         {
            name: "Indianapolis",
            slug: "indianapolis-in"
         },
         {
            name: "Lafayette",
            slug: "lafayette-in"
         },
         {
            name: "Scottsburg",
            slug: "scottsburg-in"
         }
       ]
   },
   {
       text: "Iowa (IA)",
       slug: "IA-Iowa",
       cities: [
         {
            name: "Cedar Rapids",
            slug: "cedarrapids-ia"
         },
         {
            name: "Des Moines",
            slug: "desmoines-ia"
         },
         {
            name: "Ames",
            slug: "ames-ia"
         }
       ]
   },
   {
       text: "Kansas (KS)",
       slug: "KS-Kansas",
       cities: [
         {
            name: "Wichita",
            slug: "wichita-ks"
         },
         {
            name: "Kansas City",
            slug: "kansas-city-ks"
         },
         {
            name: "Winfield",
            slug: "winfield-ks"
         }
       ]
   },
   {
       text: "Kentucky (KY)",
       slug: "KY-Kentucky",
       cities: [
         {
            name: "Lexington",
            slug: "lexington-ky"
         },
         {
            name: "Louisville",
            slug: "louisville-ky"
         },
         {
            name: "Middletown",
            slug: "middletown-ky"
         },
         {
            name: "Bowling Green",
            slug: "auto-transport-bowling-green"
         },
         {
            name: "Covington",
            slug: "covington-ky"
         }
       ]
   },
   {
       text: "Louisiana (LA)",
       slug: "LA-Louisiana",
       cities: [
         {
            name: "Baton Rouge",
            slug: "batonrouge-la"
         },
         {
            name: "Hammond",
            slug: "hammond-la"
         },
         {
            name: "New Orleans",
            slug: "neworleans-la"
         },
         {
            name: "Shreveport",
            slug: "shreveport-la"
         },
         {
            name: "Pierre Part",
            slug: "pierre-part-la"
         },
         {
            name: "Jennings",
            slug: "jennings-la"
         }
       ]
   },
   {
       text: "Maine (ME)",
       slug: "ME-Maine",
       cities: [
         {
            name: "Bangor",
            slug: "bangor-me"
         },
         {
            name: "Lewiston",
            slug: "lewiston-me"
         }
       ]
   },
   {
       text: "Maryland (MD)",
       slug: "MD-Maryland",
       cities: [
         {
            name: "Baltimore",
            slug: "baltimore-md"
         },
         {
            name: "Salisbury",
            slug: "salisbury-md"
         },
         {
            name: "Annapolis",
            slug: "auto-transport-annapolis-md"
         }
       ]
   },
   {
       text: "Massachusetts (MA)",
       slug: "MA-Massachusetts",
       cities: [
         {
            name: "Boston",
            slug: "boston-ma"
         },
         {
            name: "Worcester",
            slug: "worcester-ma"
         }
       ]
   },
   {
       text: "Michigan (MI)",
       slug: "MI-Michigan",
       cities: [
         {
            name: "Flint",
            slug: "flint-mi"
         },
         {
            name: "Lansing",
            slug: "lansing-mi"
         },
         {
            name: "Michigan City",
            slug: "michigancity-mi"
         },
         {
            name: "East Lansing",
            slug: "eastlansing-mi"
         },
         {
            name: "Bay City",
            slug: "baycity-mi"
         },
         {
            name: "Jackson",
            slug: "jackson-mi"
         },
         {
            name: "Ann Arbor",
            slug: "auto-transport-ann-arbor"
         }
       ]
   },
   {
       text: "Minnesota (MN)",
       slug: "MN-Minnesota",
       cities: [
         {
            name: "St Cloud",
            slug: "st-cloud-mn"
         }
       ]
   },
   {
       text: "Mississippi (MS)",
       slug: "MS-Mississippi",
       cities: [
         {
            name: "jackson",
            slug: "jackson-ms"
         },
         {
            name: "Meridian",
            slug: "meridian-ms"
         },
         {
            name: "Gulfport",
            slug: "gulfport-ms"
         }
       ]
   },
   {
       text: "Missouri (MO)",
       slug: "MO-Missouri",
       cities: [
         {
            name: "Columbia",
            slug: "columbia-mo"
         },
         {
            name: "Kansas City",
            slug: "kansas-city-mo"
         },
         {
            name: "Springfield",
            slug: "springfield-mo"
         },
         {
            name: "Independence",
            slug: "auto-transport-independence-mo"
         }
       ]
   },
   {
       text: "Montana (MT)",
       slug: "MT-Montana",
       cities: [
         {
            name: "Billings",
            slug: "billings-mt"
         },
         {
            name: "Bozeman",
            slug: "bozeman-mt"
         },
         {
            name: "Helena",
            slug: "auto-transport-helena-mt"
         }
       ]
   },
   {
       text: "Nebraska (NE)",
       slug: "NE-Nebraska",
       cities: [
         {
            name: "Omaha",
            slug: "omaha-ne"
         },
         {
            name: "Kearney",
            slug: "kearney-ne"
         }
       ]
   },
   {
       text: "Nevada (NV)",
       slug: "NV-Nevada",
       cities: [
         {
            name: "Reno",
            slug: "reno-nv"
         },
         {
            name: "Las Vegas",
            slug: "las-vegas-nv"
         }
       ]
   },
   {
       text: "New Hampshire (NH)",
       slug: "NH-New-Hampshire"
   },
   {
       text: "New Jersey (NJ)",
       slug: "NJ-New-Jersey",
       cities: [
         {
            name: "Riverside",
            slug: "riverside-nj"
         }
       ]
   },
   {
       text: "New Mexico (NM)",
       slug: "NM-New-Mexico",
       cities: [
         {
            name: "Albuquerque",
            slug: "albuquerque-nm"
         },
         {
            name: "Las Cruces",
            slug: "lascruces-nm"
         }
       ]
   },
   {
       text: "New York (NY)",
       slug: "NY-New-York",
       cities: [
         {
            name: "Binghamton",
            slug: "binghamton-ny"
         },
         {
            name: "New York City",
            slug: "nyc-new-york-city-ny"
         },
         {
            name: "Plattsburgh",
            slug: "plattsburgh-ny"
         },
         {
            name: "Buffalo",
            slug: "buffalo-ny"
         },
         {
            name: "Schenectady",
            slug: "schenectady-ny"
         },
         {
            name: "Ithaca",
            slug: "ithaca-ny"
         },
         {
            name: "Elmira",
            slug: "elmira-ny"
         },
         {
            name: "Watertown",
            slug: "watertown-ny"
         },
         {
            name: "Utica",
            slug: "utica-ny"
         }
       ]
   },
   {
       text: "North Carolina (NC)",
       slug: "NC-North-Carolina",
       cities: [
         {
            name: "Raleigh",
            slug: "raleigh-nc"
         },
         {
            name: "Gastonia",
            slug: "gastonia-nc"
         },
         {
            name: "High Point",
            slug: "highpoint-nc"
         },
         {
            name: "Greensboro",
            slug: "grennsboro-nc"
         },
         {
            name: "Wilmington",
            slug: "wilmington-nc"
         }
       ]
   },
   {
       text: "North Dakota (ND)",
       slug: "ND-North-Dakota",
       cities: [
         {
            name: "Wahpeton",
            slug: "wahpeton-nd"
         },
         {
            name: "West Fargo",
            slug: "auto-transport-west-fargo"
         },
         {
            name: "Minot",
            slug: "minot-nd"
         },
         {
            name: "Bismarck",
            slug: "bismarck-nd"
         },
       ]
   },
   {
       text: "Ohio (OH)",
       slug: "OH-Ohio",
       cities: [
         {
            name: "Youngstown",
            slug: "youngstown-oh"
         },
         {
            name: "Cleveland",
            slug: "cleveland-oh"
         },
         {
            name: "Cincinnati",
            slug: "cincinnati-oh"
         },
         {
            name: "Dayton",
            slug: "dayton-oh"
         },
         {
            name: "East Liverpool",
            slug: "eastliverpool-oh"
         },
         {
            name: "Akron",
            slug: "auto-transport-akron"
         },
       ]
   },
   {
       text: "Oklahoma (OK)",
       slug: "OK-Oklahoma",
       cities: [
         {
            name: "Tulsa",
            slug: "tulsa-ok"
         },
         {
            name: "Oklahoma City",
            slug: "oklahoma-city-ok"
         },
         {
            name: "Shawnee",
            slug: "shawnee-ok"
         },
         {
            name: "Norman",
            slug: "auto-transport-norman"
         },
       ]
   },
   {
       text: "Oregon (OR)",
       slug: "OR-Oregon",
       cities: [
         {
            name: "Eugene",
            slug: "eugene-or"
         },
         {
            name: "Bend",
            slug: "bend-or"
         },
         {
            name: "Portland",
            slug: "portland-or"
         },
         {
            name: "Gresham",
            slug: "auto-transport-gresham"
         },
       ]
   },
   {
       text: "Pennsylvania (PA)",
       slug: "PA-Pennsylvania",
       cities: [
         {
            name: "Harrisburg",
            slug: "harrisburg-pa"
         },
         {
            name: "Philadelphia",
            slug: "philadelphia-pa"
         },
         {
            name: "Pittsburgh",
            slug: "pittsburgh-pa"
         },
         {
            name: "Hanover",
            slug: "hanover-pa"
         },
         {
            name: "State College",
            slug: "statecollege-pa"
         },
       ]
   },
   {
       text: "Rhode Island (RI)",
       slug: "RI-Rhode-Island"
   },
   {
       text: "South Carolina (SC)",
       slug: "SC-South-Carolina",
       cities: [
         {
            name: "Columbia",
            slug: "columbia-sc"
         },
         {
            name: "Charleston",
            slug: "charleston-sc"
         },
         {
            name: "Myrtle Beach",
            slug: "myrtle-beach-sc"
         },
         {
            name: "Greenville",
            slug: "greenville-sc"
         },
       ]
   },
   {
       text: "South Dakota (SD)",
       slug: "SD-Sotuh-Dakota"
   },
   {
       text: "Tennessee (TN)",
       slug: "TN-Tennessee",
       cities: [
         {
            name: "Clarksville",
            slug: "clarksville-tn"
         },
         {
            name: "Chattanooga",
            slug: "Chattanooga-tn"
         },
         {
            name: "Memphis",
            slug: "memphis-tn"
         },
         {
            name: "Knoxville",
            slug: "knoxville-tn"
         },
         {
            name: "Nashville",
            slug: "nashville-tn"
         },
         {
            name: "Lakewood",
            slug: "lakewood-tn"
         },
         {
            name: "Johnson City",
            slug: "johnsoncity-tn"
         },
       ]
   },
   {
       text: "Texas (TX)",
       slug: "TX-Texas",
       cities: [
         {
            name: "Dallas",
            slug: "dallas-fort-worth-tx"
         },
         {
            name: "Amarillo",
            slug: "amarillo-tx"
         },
         {
            name: "Beaumont",
            slug: "beaumont-tx"
         },
         {
            name: "El Pado",
            slug: "el-paso-tx"
         },
         {
            name: "Harlingen",
            slug: "harlingen-tx"
         },
         {
            name: "Raymondville",
            slug: "raymondville-tx"
         },
         {
            name: "Lubbock",
            slug: "lubbock-tx"
         },
         {
            name: "Marshall",
            slug: "marshall-tx"
         },
         {
            name: "Odessa",
            slug: "odessa-tx"
         },
         {
            name: "San Antonio",
            slug: "sanantonio-tx"
         },
         {
            name: "Killeen",
            slug: "killeen-tx"
         },
         {
            name: "Fort Hood",
            slug: "forthood-tx"
         },
         {
            name: "Texarkana",
            slug: "texarkana-tx"
         },
         {
            name: "McAllen",
            slug: "mcallen-tx"
         },
         {
            name: "Houston",
            slug: "auto-transport-houston-tx"
         },
       ]
   },
   {
       text: "Utah (UT)",
       slug: "UT-Utah"
   },
   {
       text: "Vermont (VT)",
       slug: "VT-Vermont"
   },
   {
       text: "Virginia (VA)",
       slug: "VA-Virginia",
       cities: [
         {
            name: "Richmond",
            slug: "richmond-va"
         },
         {
            name: "Norfolk",
            slug: "norfolk-va"
         },
         {
            name: "Charlottesville",
            slug: "charlottesville-va"
         },
         {
            name: "Newport News",
            slug: "auto-transport-newport-news-va"
         },
       ]
   },
   {
       text: "Washington (WA)",
       slug: "WA-Washington",
       cities: [
         {
            name: "Tacoma",
            slug: "tacoma-wa"
         },
         {
            name: "Olympia",
            slug: "olympia-wa"
         },
         {
            name: "Spokane",
            slug: "spokane-wa"
         },
         {
            name: "Bremerton",
            slug: "bremerton-wa"
         },
         {
            name: "Bellingham",
            slug: "bellingham-wa"
         },
         {
            name: "Seattle",
            slug: "auto-transport-seattle"
         },
         {
            name: "Bellevue",
            slug: "auto-transport-bellevue-wa"
         },
       ]
   },
   {
       text: "West Virginia (WV)",
       slug: "WV-West-Virginia",
       cities: [
         {
            name: "Parkersburg",
            slug: "parkersburg-wv"
         },
       ]
   },
   {
       text: "Wisconsin (WI)",
       slug: "WI-Wisconsin",
       cities: [
         {
            name: "Madison",
            slug: "madison-wi"
         },
         {
            name: "Menomonie",
            slug: "menomonie-wi"
         },
         {
            name: "Green Bay",
            slug: "greenbay-wi"
         },
         {
            name: "Kenosha",
            slug: "auto-transport-kenosha-wi"
         },
       ]
   },
   {
       text: "Wyoming (WY)",
       slug: "WY-Wyoming",
       cities: [
         {
            name: "Gillette",
            slug: "auto-transport-gillette-wy"
         },
         {
            name: "Rock Springs",
            slug: "auto-transport-rock-springs-wy"
         },
       ]
   },
]

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

// export const getVehicleMakes = async(year) => {
//     const url = `https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForManufacturerAndYear/a?year=${year}&format=json`
//     const url2 = `https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/car?format=json`
//     const res1  = await fetch(url)
//          .then((res) => res.json())
//          .then((data) => {
//             const results = data.Results
//             return results
//          })
//          .catch((err) => {
//             console.log(err.message);
//          });

//     const res2 = await fetch(url2)
//          .then((res) => res.json())
//          .then((data) => {
//             const results = data.Results
//             let formattedArr = []
//             results.forEach((el) => {
//               formattedArr.push(el.MakeName)
//             })
//             return formattedArr
//          })

         

//         const filteredResults = res1.filter((el) => res2.includes(el.MakeName))
//         let allResults = []
//         filteredResults.forEach((el) => {
//           allResults.push(el.MakeName)
//         })
//         let uniqueResults = [...new Set(allResults)].sort((a, b) => a.localeCompare(b));
//         uniqueResults.push(`I don't see my Make`)
//         return uniqueResults
//   }

  export const getVehicleMakes = async(year) => {
   const url = 'https://us-central1-aas-crm.cloudfunctions.net/getVehicleMakes'
   const data = {
         "data": {
            "year": year.toString()
         }
   }
   const response = await fetch(url, {
      method: "POST",
      mode: 'cors',
      headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(data), 
    })
    const d = await response.json();
    let formattedArr = []
      if(d !== undefined && d !== null) {
         d.forEach((el) => {
            formattedArr.push(el.make)
         })
      }
    return formattedArr
  }


  export const getVehicleModels = async(vehicleMake, vehicleYear) => {
   const url = 'https://us-central1-aas-crm.cloudfunctions.net/getVehicleModels'
   const data = {
         "data": {
            "year": vehicleYear,
            "make": vehicleMake
         }
   }
   const response = await fetch(url, {
      method: "POST",
      mode: 'cors',
      headers: {
         'accept': 'application/json',
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(data), 
    })
    const d = await response.json();
    let formattedArr = []
      if(d !== undefined && d !== null) {
         d.forEach((el) => {
            formattedArr.push(el.model)
         })
      }
    return formattedArr
 }


// export const getVehicleModels = async(vehicleMake, vehicleYear) => {
//     const url = `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeYear/make/${vehicleMake}/modelyear/${vehicleYear}?format=json`
//     const res1  = await fetch(url)
//          .then((res) => res.json())
//          .then((data) => {
//             const results = data.Results
//             return results
//          })
//          .catch((err) => {
//             console.log(err.message);
//          });
//          let formattedArr = []
//          if(res1 !== undefined && res1 !== null) {
//             res1.forEach((el) => {
//                formattedArr.push(el.Model_Name)
//             })
//          }
//          formattedArr.push(`I don't see my Model`)
//          return formattedArr
//   }

// export const getVehicleYears = () => {
//     var max = new Date().getFullYear()
//     var min = max - 140
//     var years = []
  
//     for (var i = max; i >= min; i--) {
//       years.push(i.toString())
//     }
//     return years
//   }

export const getVehicleYears = async() => {
   const url = 'https://us-central1-aas-crm.cloudfunctions.net/getVehicleYears'
   const res1  = await fetch(url)
         .then((res) => res.json())
         .then((data) => {
            return data
         })
         .catch((err) => {
            console.log(err.message);
         });
         let formattedArr = []
         if(res1 !== undefined && res1 !== null) {
            res1.forEach((el) => {
               formattedArr.push(el.year.toString())
            })
         }
         console.log(formattedArr)
         return formattedArr
  }

export const filterPostDescription = (text) => {
   const regex = /(<([^>]+)>)/ig;
   const result = text.replace(regex, '');
   return result
}


export const getBlogData = async(limitVal) => {
   const result = API.graphql(
      graphqlOperation(postsByDate, {
         status: "publish",
         fromDateYear: "2012",
         limit: limitVal
      })
    );
   const d = await result;
   return d
  }



export const getBlogDataDate = async() => {
   const result = API.graphql(
      graphqlOperation(postsByDate, {
         status: "publish",
         fromDateYear: "2022",
         limit: 3
      })
    );
   const d = await result;
   return d
}

export const getBlogDataByPage = async(page) => {

   const result = API.graphql(
      graphqlOperation(getPages, {
         slug: page
      })
    );
   const d = await result;
   console.log(d)
   return d
}

export const getBlogPostCategoryById = (id) => {
   const data = fetch(EnvVariables.API_CATEGORY_BY_ID.concat(id), {cache: 'force-cache'}).then((res) => {
       return res.json();
   });
   return data
}

export const getBlogPostBySlug = async(slug) => {
   const result = API.graphql(
      graphqlOperation(getPosts, {
         slug: slug
      })
    );
   const d = await result;
   console.log(d)
   return d
}

export const getPageBySlug = async(slug) => {
   const result = API.graphql(
      graphqlOperation(getPages, {
         slug: slug
      })
    );
   const d = await result;
   return d
}

export const replaceDomain = (oldDomain) => {
   let newDomain = oldDomain.replace("https://www.americanautoshipping.com/wp-content/uploads/", EnvVariables.AAS_CDN_DOMAIN)
   return newDomain
}

export const removeVCShortCode = ( text ) => {
   return text.replace(/\[.*?\]/g, "");
	// return text.replace(/\[.*?\]/g, "");
}

export const replaceOldImageLinks = ( text ) => {
   return text.replaceAll(/http:\/\/www.americanautoshipping.com\/wp-content\/uploads\//g, EnvVariables.AAS_CDN_DOMAIN).replaceAll(/https:\/\/www.americanautoshipping.com\/wp-content\/uploads\//g, EnvVariables.AAS_CDN_DOMAIN)
}

export const cleanAddress = ( text ) => {
   return text.replaceAll(" Usa", "")
}

export const findReferrer = () => {
   const url = window.parent.location.href;

   if(url.includes('utm_source=GoogleAds')){
      return "GoogleAds"
   }
   else if(url.includes('utm_source=automedia')) {
      return "automedia"
   }
   else if(url.includes('utm_source=consumervoice')) {
      return "consumervoice"
   }
   else if(url.includes('utm_source=moveorg')) {
      return "moveorg"
   }
   else if(url.includes('utm_source=consumeraffairs')) {
      return "consumeraffairs"
   }
   else {
      return "web"
   }
}


export const CustomTextField = styled(TextField)(
   {
      '& label.Mui-focused': {
      color: 'black',
      },
      '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
      },
      '& .MuiOutlinedInput-root': {
      '& fieldset': {
          borderColor: 'black',
      },
      '&:hover fieldset': {
          borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
          borderColor: 'black',
      },
      },
   });

export const HoverMenu = styled(({ className, ...props }) => (
   <Tooltip disabled {...props} classes={{ popper: className }} />
 ))(({ theme }) => ({
   [`& .${tooltipClasses.tooltip}`]: {
     backgroundColor: 'white',
     color: 'rgba(0, 0, 0, 0.87)',
     boxShadow: "2px 1px 3px #9E9E9E",
     width: '500px',
     maxWidth: 'none'
   },
 }));