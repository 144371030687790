import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { GlobalStyles } from "../GlobalStyles";
import { useNavigate } from "react-router-dom";
import SVG_WORKSPACE from '../assets/SVG_WORKSPACE.svg'

type ErrorPageProps = {
    error?: string
}

export default function ErrorPage({error}: ErrorPageProps) {
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate(`/`, { replace: true})
    }

    return(
        <Box style = {GlobalStyles.loading}>

            <Typography style = {GlobalStyles.headerFontAltSmall}>
            Whoops... We are sorry! It looks like there's an issue.
            </Typography>
            {error &&
            <Typography style = {GlobalStyles.subHeaderFontAlt}>
                {error}
            </Typography>
            }

            <img src={SVG_WORKSPACE} alt='SVG_WORKSPACE' style = {{height: '200px', width: '200px'}}/>

            <Button onClick = {navigateHome} sx = {{backgroundColor: 'black', borderRadius: '4px', marginTop: '32px'}}>
                <Typography style={GlobalStyles.buttonText}>
                    Return Home
                </Typography>
            </Button>
        </Box>
)
}