export const colors = {
  primary: {
    'primary': '#000000',
    'secondary': '#FFFFFF',
    'alternate': '#1976d3',
    'green': '#3be23b'
  },
  oldsite: {
    'alternate': "#1e73be",
    'background': "#f5f5f5"
  }
};