import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { colors } from "../../theme"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from "@mui/material";



export default function QuoteThankYouPage(){

    const styles = {
      thankYouFont: {
          fontFamily: 'NunitoSans',
          color: colors.primary.secondary,
          fontSize: 32,
          fontWeight: 900,
      },
      thankYouSubFont: {
          fontFamily: 'NunitoSans',
          color: colors.primary.secondary,
          fontWeight: 700,
          fontSize: 16,
      },
      button: {
        backgroundColor: colors.oldsite.alternate
    },
    buttonFont: {
        fontFamily: 'NunitoSans',
        color: colors.primary.secondary,
        fontWeight: 900
    }
    }

    return(
        <Grid container sx = {{paddingTop: '100px', backgroundColor: colors.primary.alternate, paddingBottom: '50px', height: '100vh', width: '100%'}}>

                <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', justifyContent: 'center', paddingTop: '16px'}}>

                        <div>
                            <CheckCircleIcon sx = {{color: colors.primary.green, fontSize: 64}} />
                        </div>

                        <div>
                            <Typography style = {styles.thankYouFont}>
                                Thank you!
                            </Typography>
                        </div>

                        <div>
                            <Typography style = {styles.thankYouSubFont}>
                                We've got your order! We will be in touch with you shortly.
                            </Typography>
                        </div>

                        <div style = {{marginTop: '16px'}}>
                            <Link href = "https://www.americanautoshipping.com" target = "_parent">
                            <Button style = {styles.button}>
                                    <Typography style = {styles.buttonFont}>Return Home</Typography>
                            </Button>
                            </Link>
                        </div>
                </Grid>
        </Grid>
    )
}