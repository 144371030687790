import { useEffect, useState, lazy, Suspense, createContext } from 'react';

import './App.css';
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import Loading from './navigation/Loading';
import { Amplify } from 'aws-amplify'
//import config from './aws-exports'
import ErrorPage from './navigation/ErrorPage';
// import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import { initializeApp } from "firebase/app";
import { FIREBASE_CONFIG } from './EnvVariables';
import QuoteThankYouPage from './components/Quote/QuoteThankYouPage';
import NavBar from './navigation/NavBar/NavBar';
import Home from './components/Home/Home';
import Background from './assets/background-form.png';
import Footer from './navigation/Footer/Footer';

const GoogleMapsInit = lazy(() => import('./components/GoogleMapsInit'));
const Quote = lazy(() => import('./components/Quote/Quote'))

const app = initializeApp(FIREBASE_CONFIG);
ReactGA.initialize('UA-2930488-1');
//Amplify.configure(config)

const getNumOrders = () => {
  let num = Math.floor(Math.random() * (700 - 600 + 1)) + 600;
  return `${num} orders booked`
}

type PageContextProps = {
  WINDOW_WIDTH: any,
  WINDOW_HEIGHT: any,
  isMobile: boolean
}

let initialData: PageContextProps = {
  WINDOW_WIDTH: null,
  WINDOW_HEIGHT: null,
  isMobile: false
}

export const PageContext = createContext(initialData);

function getWindowDimensions() {
  const { innerWidth: WINDOW_WIDTH, innerHeight: WINDOW_HEIGHT } = window;
  const isMobile = WINDOW_WIDTH <= 900;
  return {
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
    isMobile
  };
}

let numOrders = getNumOrders();

function MainLayout() {
  return(
    <div style = {{display: 'flex', flexDirection: 'column'}}>
        <NavBar />
        <div style = {{paddingTop: '150px'}}>
        <Quote />
        </div>

        <div style = {{paddingTop: '125px'}}>
          <Home />
        </div>
        
    </div>
  )
}

function AppLayout() {

    return(
          <HashRouter>
                <Suspense fallback = {<></>}>
                  <GoogleMapsInit />
                </Suspense>
                  
                    <Routes>
                      <Route path="/" errorElement = {<ErrorPage />} element={<Suspense fallback = {<Loading loader adjustForNavBar />}><MainLayout /></Suspense>} />
                      <Route path="/quote" errorElement = {<ErrorPage />} element={<Suspense fallback = {<Loading loader adjustForNavBar />}><Quote /></Suspense>} />
                      <Route path="/:id" errorElement = {<ErrorPage />} element={<Suspense fallback = {<Loading loader adjustForNavBar />}><MainLayout /></Suspense>} />
                      <Route path="/thankyou" errorElement = {<ErrorPage />} element={<Suspense fallback = {<Loading loader adjustForNavBar />}><QuoteThankYouPage /></Suspense>} />
                    </Routes>

                    
        
          </HashRouter>
    )
}

function App() {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return(
          <PageContext.Provider value = {windowDimensions}>
            <div className = "App">
            <AppLayout />
            <Footer />
            </div>
          </PageContext.Provider>
  )
}

export default App;
