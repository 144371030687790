const EnvVariables = {
        STRIPE_ENDPOINT: "https://us-central1-aas-crm.cloudfunctions.net/app/customerdata/aas/stripe",
        SHIP_CARS_API_KEY: "dr7srcpomdfup0i764wc2jsdpcmyl80r33tbccqm",
        SHIP_CARS_QUOTE_URL: "https://americanautoshipping-tms.ship.cars/checkout/api/calculations",
        SHIP_CARS_QUOTE_ENDPOINT: "https://us-central1-aas-crm.cloudfunctions.net/getQuote-Website",
        LOOKUP_ZIP_ENDPOINT: "https://us-central1-aas-crm.cloudfunctions.net/lookupZip",
        UPDATE_ORDER_ENDPOINT: "https://us-central1-aas-crm.cloudfunctions.net/updateOrder",
        MAKE_PAYMENT_ENDPOINT: "https://us-central1-aas-crm.cloudfunctions.net/makePayment",
        GET_QUOTE_EXTERNAL_EMAILS_ENDPOINT: "https://us-central1-aas-crm.cloudfunctions.net/getQuote-ExternalEmail",
        API: "https://www.americanautoshipping.com/wp-json/wp/v2/posts/?per_page=50",
        API_BY_PAGE: "https://www.americanautoshipping.com/wp-json/wp/v2/posts/?per_page=50&page=",
        API_BY_ID: "https://www.americanautoshipping.com/wp-json/wp/v2/posts/",
        API_BY_SLUG: "https://www.americanautoshipping.com/wp-json/wp/v2/posts/?slug=",
        API_PAGE_BY_SLUG: "https://www.americanautoshipping.com/wp-json/wp/v2/pages/?slug=",
        API_GET_MEDIA: "https://www.americanautoshipping.com/wp-json/wp/v2/media/",
        API_CATEGORY_BY_ID: "https://www.americanautoshipping.com/wp-json/wp/v2/categories/?post=",
        DOMAIN_NAME: "localhost:3000/",
        GOOGLE_MAPS_API_KEY: "AIzaSyAUDnb5k_8ktfm_U8ldHivD_qHOxkovaiE",
        AAS_CDN_DOMAIN: "https://d2wesant1t5iow.cloudfront.net/"
}

const FIREBASE_CONFIG = {
        apiKey: "AIzaSyDUGbOzbQylL8mVMULTE6JttW6MtAgfX5s",
        authDomain: "aas-crm.firebaseapp.com",
        databaseURL: "https://aas-crm-default-rtdb.firebaseio.com",
        projectId: "aas-crm",
        storageBucket: "aas-crm.appspot.com",
        messagingSenderId: "11223535528",
        appId: "1:11223535528:web:eaa17afc05790e928c179d",
        measurementId: "G-D2XDMDBDMC"
      };

// eslint-disable-next-line import/no-anonymous-default-export
module.exports = { EnvVariables, FIREBASE_CONFIG }