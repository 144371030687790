import { GlobalStyles } from "../GlobalStyles";
import { colors } from "../theme";

type LoadingProps = {
    loader?: boolean
    adjustForNavBar?: boolean
}

function Loading({loader, adjustForNavBar}: LoadingProps) {
    return(
        <div style = {adjustForNavBar ? GlobalStyles.loadingAdjusted : GlobalStyles.loading}>
            {loader && 
                <span style = {{color: colors.oldsite.alternate, fontFamily: 'NunitoSans-Bold', fontWeight: 900, fontSize: 24}}>
                    Loading...
                </span>
                }
        </div>
    )
}

export default Loading