import { colors } from "./theme";

export const GlobalStyles = {
    mainContainer: {
        display: 'flex'
    },
    loading: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', 
        width: '100vw'
    } as React.CSSProperties,
    maintenance: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', 
        width: '100vw'
    } as React.CSSProperties,
    loadingAdjusted: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 100px)', 
        width: '100vw'
    } as React.CSSProperties,
    navbarText: {
        color: 'black',
        fontFamily: 'Montserrat-Bold',
        fontWeight: 700,
        fontSize: 14,
        overflow: "hidden", 
        textOverflow: "ellipsis"
    },
    navbarButton: {
        backgroundColor: 'transparent'
    },
    buttonText: {
        fontFamily: 'Montserrat-ExtraBold',
        fontWeight: 900,
        color: 'white'
    } as React.CSSProperties,
    headerFont: {
        fontFamily: 'Montserrat-ExtraBold',
        fontWeight: 900,
        color: 'white',
        fontSize: 32
    },
    headerFontLight: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: 700,
        color: 'black',
        fontSize: 16,
        opacity: 0.6
    },
    headerFontLightAlt: {
        fontFamily: 'Montserrat-Bold',
        textTransform: 'uppercase',
        color: colors.primary.alternate,
        fontSize: 16
    } as React.CSSProperties,
    headerFontAlt: {
        fontFamily: 'Montserrat-ExtraBold',
        fontWeight: 900,
        color: colors.primary.primary,
        fontSize: 32
    },
    headerFontAltSmall: {
        fontFamily: 'Montserrat-ExtraBold',
        fontWeight: 900,
        color: colors.primary.primary,
        fontSize: 24
    },
    headerFontAlt2: {
        fontFamily: 'Montserrat-ExtraBold',
        fontWeight: 900,
        color: colors.primary.alternate,
        fontSize: 32
    },
    headerFontCounter: {
        fontFamily: 'Montserrat-ExtraBold',
        color: colors.primary.primary,
        fontSize: 40
    },
    headerFontAltCounter: {
        fontFamily: 'Montserrat-ExtraBold',
        fontWeight: 900,
        color: colors.primary.alternate,
        fontSize: 40
    },
    headerFont3: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        color: colors.primary.primary,
        fontSize: 14
    },
    headerFont4: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400,
        color: colors.primary.primary,
        fontSize: 14
    },
    subHeaderFont: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: 700,
        color: 'white',
        fontSize: 24
    } as React.CSSProperties,
    subHeaderFontLight: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        color: 'white',
        fontSize: 14
    },
    subHeaderFontLightAlt: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        color: 'black',
        fontSize: 14
    },
    subHeaderFontAlt: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: 700,
        color: 'black',
        fontSize: 24
    },
    mainFont: {
        fontFamily: 'Montserrat',
        fontWeight: 400,
        color: colors.primary.primary,
        fontSize: 14
    },
    mainFontAlt: {
        fontFamily: 'Montserrat',
        color: colors.primary.alternate,
        fontSize: 14
    },
}