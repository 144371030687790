import { GlobalStyles } from "../../GlobalStyles";
import { colors } from "../../theme";
import Typography from "@mui/material/Typography"


type FooterProps = {

}

function Footer({}: FooterProps) {
    return(
        <div style = {{width: '100%', height: '100px', alignItems: 'center', backgroundColor: 'rgba(128,128,128, 0.11)', marginTop: '32px'}}>
            <Typography sx = {{fontSize: '12px', paddingTop: '8px', fontFamily: 'NunitoSans'}}>
                Copyright 2024 - American Transport Specialists - DOT: 4225537 / MC: 1699907
            </Typography>
        </div>
    )
}

export default Footer