import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { NavBarStyles } from "./NavBarStyles"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"

import { Link } from "react-router-dom";

import DehazeIcon from '@mui/icons-material/Dehaze';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MapIcon from '@mui/icons-material/Map';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import FlagIcon from '@mui/icons-material/Flag';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import WarningIcon from '@mui/icons-material/Warning';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import { GlobalStyles } from "../../GlobalStyles"

type NavBarMenuMobileProps = {
    navigateToPage: (page: string) => void
    handleClick: (event: React.MouseEvent<HTMLElement>) => void
    handleClose: () => void
    anchorEl: null | HTMLElement
    open: boolean

}

export default function NavBarMenuMobile({navigateToPage, handleClick, handleClose, anchorEl, open}: NavBarMenuMobileProps) {
    return(
        <>
            <Grid item 
                    xs={10} sm={10} md={10} lg = {10}
                    sx={{
                        display: { xs: "block", sm: "block", md: "none", lg: "none" }
                      }}
                    >
                        <div style = {NavBarStyles.navbarLayoutAlt}>
                            <Button aria-label = "menuButton" onClick={handleClick}>
                                <DehazeIcon style = {{color: 'black'}}/>
                            </Button>
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >  
                                <Link to = "">
                                <MenuItem>
                                    <Typography style = {GlobalStyles.navbarText}>
                                        Home
                                    </Typography>
                                </MenuItem>
                                </Link>

                                {/* <Link to = "">
                                <MenuItem>
                                    <Typography style = {GlobalStyles.navbarText}>
                                        Why us?
                                    </Typography>
                                </MenuItem>
                                </Link>

                                <Accordion disabled style={{ boxShadow: "none" }}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        <Typography style = {GlobalStyles.navbarText}>
                                            Auto Shipping Services
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <div style = {{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("cross-country-auto-transport")}}>
                                                    <MapIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Cross Country
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("open-auto-transport")}}>
                                                    <DriveEtaIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Open Transport
                                                    </Typography>
                                                </MenuItem>
                                            </div> 
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("enclosed-auto-transport")}}>
                                                    <LocalShippingIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Enclosed Transport
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("auto-transport-rail")}}>
                                                    <DirectionsTransitIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;By Rail
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => navigateToPage("military-auto-shipping")}>
                                                    <FlagIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Military Car Shipping
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => navigateToPage("auction-car-transport-services")}>
                                                    <GavelIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Auto Auction Shipping
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("door-to-door-auto-transport")}}>
                                                    <HomeIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Door To Door
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("ship-car-from-hawaii-to-mainland")}}>
                                                    <PublicIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;From Hawaii To Mainland
                                                    </Typography>
                                                </MenuItem>
                                            </div>     
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("ship-car-from-california-to-hawaii")}}>
                                                    <BeachAccessIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;From California to Hawaii
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("heavy-equipment-transportation")}}>
                                                    <WarningIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Heavy Equipment Transport
                                                    </Typography>
                                                </MenuItem>
                                            </div>
                                            <div style = {NavBarStyles.menuItemMobile}>
                                                <MenuItem onClick={() => {navigateToPage("motorcycle-transport-services")}}>
                                                    <TwoWheelerIcon fontSize = "small" />
                                                    <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                        &nbsp;Motorcycle Shipping
                                                    </Typography>
                                                </MenuItem>
                                            </div>                       
                                        
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion disabled style={{ boxShadow: "none" }}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        <Typography style = {GlobalStyles.navbarText}>
                                            Useful Information
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <div style = {{height: '225px', display: 'flex', flexWrap: 'wrap', width: '100%'}}>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick={() => {navigateToPage("cheapest-way-to-ship-a-car")}}>
                                                <MapIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Cheapest Way To Ship A Car
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick={() => {navigateToPage("auto-shipping-faq")}}>
                                                <DriveEtaIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;FAQS
                                                </Typography>
                                            </MenuItem>
                                        </div> 
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick={() => {navigateToPage("how-much-does-it-cost-to-ship-a-car")}}>
                                                <LocalShippingIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;How Much Does Shipping A Car Cost
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick={() => {navigateToPage("auto-transport-process-start-finish")}}>
                                                <DirectionsTransitIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Auto Transport: Start To Finish
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick={() => {navigateToPage("auto-shipping-rates-calculator")}}>
                                                <FlagIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Auto Shipping Rates Calculator
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick = {() => navigateToPage("auto-shipping-broker-vs-car-carrier")}>
                                                <GavelIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Brokers Vs. Carriers
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick = {() => navigateToPage("differences-open-enclosed-auto-transportation-services")}>
                                                <HomeIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Open Vs. Enclosed
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick = {() => navigateToPage("driving-vs-auto-transport-analysis")}>
                                                <PublicIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Driving Vs. Auto Transport
                                                </Typography>
                                            </MenuItem>
                                        </div>     
                                        <div style = {NavBarStyles.menuItemMobile}>
                                            <MenuItem onClick = {() => navigateToPage("shipping-a-car-vs-towing-a-car-what-to-know")}>
                                                <BeachAccessIcon fontSize = "small" />
                                                <Typography noWrap style = {NavBarStyles.hoverMenuRegularTextBold}>
                                                    &nbsp;Shipping A Car Vs. Towing A Car
                                                </Typography>
                                            </MenuItem>
                                        </div>                                                         
                                    </div>
                                    </AccordionDetails>
                                </Accordion>

                                <MenuItem>
                                    <Typography style = {GlobalStyles.navbarText}>
                                        About us
                                    </Typography>
                                </MenuItem>
                                
                                <Link to = "">
                                <MenuItem>
                                        <Typography style = {GlobalStyles.navbarText}>
                                            Blog
                                        </Typography>
                                </MenuItem>
                                </Link> */}

                            </Menu>
                        </div>
                </Grid>
            </>
    )
}