
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AnimatedNumbers from "react-animated-numbers";

import { GlobalStyles } from "../../GlobalStyles";
import {Suspense} from 'react'

export default function Home() {
    return(
        <>

        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            Auto Transport Services: Safe, Reliable, and Efficient
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                Auto transport is a crucial service for individuals and businesses needing to move vehicles from one location to another. Whether you're relocating, purchasing a vehicle from out of state, or need to transport a fleet of cars for your business, auto transport services provide a convenient and secure solution. This guide will explore the benefits, types, and processes of auto transport, ensuring you have all the information you need to make an informed decision.
                </Typography>

            </div>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} lg={12} sx ={{paddingTop: '64px'}}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            What is Auto Transport?
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                Auto transport, also known as car shipping or vehicle transport, involves moving vehicles from one place to another using specialized carriers. These carriers range from open trailers to enclosed trucks, each designed to ensure the safe and efficient transport of your vehicle. Auto transport services cater to various needs, from individual car owners to large corporations requiring fleet transportation.
                </Typography>

            </div>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} lg={12} sx ={{paddingTop: '64px'}}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            Benefits of Auto Transport Services
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                1. **Convenience**: Auto transport services save you time and effort. Instead of driving your vehicle long distances, which can be tiring and time-consuming, professional transporters handle the logistics for you. 
                <br></br>
                2. **Safety**: Professional auto transport companies use specialized equipment and experienced drivers to ensure your vehicle's safe delivery. This reduces the risk of damage that can occur during long drives.
                <br></br>
3. **Cost-Effective**: While it may seem expensive initially, auto transport can be more cost-effective than driving the vehicle yourself. Consider the costs of fuel, accommodation, food, and potential wear and tear on your vehicle.
<br></br>
4. **Time-Saving**: Auto transport allows you to focus on other important tasks while your vehicle is being transported. This is especially beneficial for businesses that need to move multiple vehicles efficiently.
<br></br>
5. **Long-Distance Moving**: Auto transport is ideal for long-distance moves, whether relocating to a different state or country. It simplifies the moving process and ensures your vehicle arrives safely.
                </Typography>

            </div>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} lg={12} sx ={{paddingTop: '64px'}}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            Types of Auto Transport Services
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                1. **Open Auto Transport**: This is the most common and cost-effective method of transporting vehicles. Cars are loaded onto an open trailer, exposed to the elements but securely fastened. It's suitable for standard vehicles and short to medium distances.
                <br></br>
2. **Enclosed Auto Transport**: For added protection, enclosed transport is available. Vehicles are loaded into an enclosed trailer, shielding them from weather conditions and road debris. This option is ideal for luxury, classic, or high-value cars.
<br></br>
3. **Door-to-Door Service**: With door-to-door service, the transport company picks up and delivers your vehicle to specified locations, such as your home or office. This service offers maximum convenience.
<br></br>
4. **Terminal-to-Terminal Service**: This service involves dropping off and picking up your vehicle at designated terminals. It's often less expensive than door-to-door service but may require additional travel.
<br></br>
5. **Expedited Shipping**: If you need your vehicle transported quickly, expedited shipping is available. This service ensures your vehicle reaches its destination in the shortest time possible.
                </Typography>

            </div>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} lg={12} sx ={{paddingTop: '64px'}}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            Choosing the Right Auto Transport Company
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                Selecting the right auto transport company is crucial for a smooth and stress-free experience. Here are some factors to consider:
                <br></br>
1. **Reputation**: Research the company's reputation by reading customer reviews and testimonials. Look for companies with high ratings and positive feedback.
<br></br>
2. **Experience**: Choose a company with extensive experience in auto transport. Experienced companies are more likely to handle your vehicle with care and navigate any challenges that arise.
<br></br>
3. **Licensing and Insurance**: Ensure the company is licensed and insured. This protects you in case of damage or loss during transport.
<br></br>
4. **Services Offered**: Check if the company offers the services you need, such as door-to-door delivery or enclosed transport.
<br></br>
5. **Pricing**: Obtain quotes from multiple companies to compare pricing. Be wary of significantly low prices, which may indicate subpar service.
<br></br>
6. **Customer Service**: Good customer service is essential for addressing any concerns or questions you may have. Choose a company that is responsive and communicates clearly.
                </Typography>

            </div>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} lg={12} sx ={{paddingTop: '64px'}}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            The Auto Transport Process
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                Understanding the auto transport process can help you prepare and ensure a smooth experience:
                <br></br>
1. **Request a Quote**: Contact auto transport companies to request quotes. Provide details about your vehicle, the pickup and delivery locations, and your preferred transport type.
<br></br>
2. **Choose a Carrier**: After comparing quotes and researching companies, choose the one that best meets your needs. Confirm the details of the transport, including the pickup and delivery dates.
<br></br>
3. **Prepare Your Vehicle**: Clean your vehicle and remove any personal items. Check for existing damage and take photos for documentation. Ensure the fuel tank is less than a quarter full.
<br></br>
4. **Pickup**: The transport company will pick up your vehicle at the agreed location. The driver will inspect the vehicle and note any existing damage.
<br></br>
5. **Transport**: Your vehicle will be loaded onto the carrier and transported to the destination. The transport company will provide updates on the progress.
<br></br>
6. **Delivery**: Upon arrival, the driver will inspect the vehicle again and compare it to the initial inspection report. You will then sign off on the delivery.
                </Typography>

            </div>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} lg={12} sx ={{paddingTop: '64px'}}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            Tips for a Successful Auto Transport Experience
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                1. **Plan Ahead**: Schedule your auto transport well in advance to ensure availability and the best rates.
                <br></br>
2. **Research**: Spend time researching companies and reading reviews to make an informed decision.
<br></br>
3. **Communicate**: Stay in contact with the transport company throughout the process to receive updates and address any concerns.
<br></br>
4. **Inspect**: Thoroughly inspect your vehicle before and after transport to ensure it arrives in the same condition.
<br></br>
5. **Be Flexible**: If possible, be flexible with pickup and delivery dates to secure better rates.
                </Typography>

            </div>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} lg={12} sx ={{paddingTop: '64px'}}>
            <Typography style = {GlobalStyles.headerFontAlt}>
            Conclusion
            </Typography>

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx = {{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '32px'}}>
                <div style = {{width: '95%'}}>

                <Typography style = {GlobalStyles.headerFontLight}>
                Auto transport services provide a reliable, convenient, and safe solution for moving vehicles over long distances. By understanding the different types of services, choosing the right company, and preparing your vehicle, you can ensure a smooth and hassle-free experience. Whether you're relocating, buying a car, or managing a fleet, auto transport is an invaluable service that simplifies the process and gives you peace of mind.
                </Typography>

            </div>
        </Grid> 

        

        


        </>
    )
}