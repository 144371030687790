import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import PhoneIcon from '@mui/icons-material/Phone';

import { NavBarStyles } from "./NavBarStyles"

import Marquee from "react-fast-marquee";

const styles = {
    bannerToolbar: {
        minHeight: '35px',
        height: '35px',
        display: 'flex', 
        alignItems: 'center', 
        backgroundColor: 'rgba(0, 0, 0, 0.05',
        width: '100%',
        zIndex: 100,
    },
    callButton: {
        backgroundColor: 'black',
        borderRadius: 32,
        minWidth: '225px',
        marginLeft: '8px'
    },

}

export default function NewsBar() {
    return(
        <>
        <div style={styles.bannerToolbar}>
            <Box sx={{display: { xs: "none", sm: "none", md: "flex", lg: "flex" }}}>
            <a aria-label = "callLink" href = "tel:+18668862871" style = {{textDecoration: 'none'}}>
            <Button aria-label = "callButton" style = {NavBarStyles.callButton}>
                
                <PhoneIcon style = {{color: 'white'}}/>
                <div style = {{paddingRight: '8px'}}>
                <Typography style = {NavBarStyles.callText}>
                    Call us - 1-866-886-2871 
                </Typography>
                </div>
            </Button>
            </a>
            </Box>

            <Box sx={{display: { xs: "flex", sm: "flex", md: "none", lg: "none" }}}>
            <a aria-label = "callLinkMobile" href = "tel:+18668862871" style = {{textDecoration: 'none'}}>
            <Button aria-label = "callButtonMobile" style = {NavBarStyles.callButtonMobile}>

                <PhoneIcon style = {{color: 'white'}}/>
                <div style = {{paddingRight: '8px'}}>
                <Typography style = {NavBarStyles.callText}>
                    1-866-886-2871 
                </Typography>
                </div>
            </Button>
            </a>
            </Box>

                <Box sx={{display: { xs: "none", sm: "none", md: "flex", lg: "flex" }}}>
                <Marquee>
                    <Typography style = {NavBarStyles.scrollText}>
                        American Transport Specialists ships safely to all 50 states! &nbsp;
                    </Typography>
                </Marquee>
                </Box>

                <Box sx={{display: { xs: "flex", sm: "flex", md: "none", lg: "none" }}}>
                <Marquee>
                    <Typography style = {NavBarStyles.scrollTextMobile}>
                        American Transport Specialists ships safely to all 50 states! &nbsp;
                    </Typography>
                    
                </Marquee>
                </Box>
            </div>
        </>
    )
}